import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
// MUI
import {
    Container,
    Paper,
    Grid,
    List,
    ListItemText,
    ListItemIcon,
    ListItemButton,
    ListSubheader,
    useMediaQuery, Stack, IconButton, Button
} from '@mui/material';
// COMPONENTS
import SettingsProfileTab from './tabs/SettingsProfileTab';
import SettingsSecurityTab from "./tabs/SettingsSecurityTab";
import SettingsBillingTab from "./tabs/SettingsBillingTab";
import SettingsOrganisationsTab from "./tabs/SettingsOrganisationsTab";
import SettingsDomainsTab from "./tabs/SettingsDomainsTab";
// MUI ICONS
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import PaymentIcon from '@mui/icons-material/Payment';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import DnsIcon from '@mui/icons-material/Dns';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Palette, VpnKey} from "@mui/icons-material";
// MODULES
import {useTranslation} from "react-i18next";
// AWS
import SettingsApiKeysTab from "./tabs/SettingsApiKeysTab";
// error management
import {useSnackbar} from "@hooks";
import {SettingsThemingTab} from "@/Views/Account/tabs";
import {useUser} from "@context";
import {useStyles} from "@/Context/Ui/DashboardUiProvider";

export default function Settings() {

    // Path detection
    const location = useLocation();
    // Error management
    const {handleSuccess, handleError} = useSnackbar()
    // API Calls to initiate stuff
    const {getCreditsDetails, getBillingMethod, getBillingInvoice} = useUser()

    const {t} = useTranslation(['settings', 'common']);
    // breakpoint
    const {theme} = useStyles()
    const downMd = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        getCreditsDetails()
        getBillingMethod()
        getBillingInvoice()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const styles = {
        paper: {
            paddingBottom: '8px',
            marginBottom: 5,
            minHeight: '80vh',
            borderRadius: 5
        },
        subheader: {backgroundColor: 'white', display: { xs: "none", md: "inline" }},
        responsiveStack: {
            flexWrap: 'wrap',
            borderRadius: 5,
            backgroundColor: "#00000010",
            m: 1
        }
    };

    const [activePage, setActivePage] = useState<null | string>("profile");

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        navigateToTab(query)
    }, [location]);

    const navigateToTab = (query: any) => {
        if (query.has("profile")) {
            setActivePage("profile");
            return;
        }
        if (query.has("billing")) {
            setActivePage("billing");
            return;
        }
        if (query.has("usage")) {
            setActivePage("billing");
            return;
        }
        if (query.has("security")) {
            setActivePage("security");
            return;
        }
        if (query.has("domains")) {
            setActivePage("domains");
            return;
        }
        if (query.has("organizations")) {
            setActivePage("organisations");
            return;
        }
        if (query.has("organisations")) {
            setActivePage("organisations");
            return;
        }
        // if (query.has("support")) { setActivePage("support"); return; }
        // if (query.has("new-support-ticket")) { setActivePage("support"); return; }
    }

    const renderActivePage = () => {
        switch (activePage) {
            case 'profile': return (<SettingsProfileTab {...{handleSuccess, handleError}} />);
            case 'billing': return (<SettingsBillingTab {...{handleSuccess, handleError}} />);
            case 'security': return (<SettingsSecurityTab {...{handleSuccess, handleError}} />);
            case 'organisations': return (<SettingsOrganisationsTab {...{handleSuccess, handleError}} />);
            case 'domains': return (<SettingsDomainsTab {...{handleSuccess, handleError}} />);
            case 'api': return (<SettingsApiKeysTab {...{handleSuccess, handleError}} />);
            case 'theming': return (<SettingsThemingTab />);
            case 'default' : return (<SettingsProfileTab {...{handleSuccess, handleError}} />);
            default: return (<SettingsProfileTab {...{handleSuccess, handleError}} />);
            // case 'support' : { return (<SupportComponent />); }
        }
    }

    const renderSettingsMenu = () => {

        const handleActivePage = (event: React.MouseEvent<HTMLElement>) => {
            if (activePage !== event.currentTarget.id) {
                setActivePage(event.currentTarget.id);
            }
        };

        const settingsList = {
            basic: [
                {
                    label: "Profile",
                    id: "profile",
                    icon: <PersonIcon/>,
                },
                {
                    label: "Billing",
                    id: "billing",
                    icon: <PaymentIcon/>,
                },
                {
                    label: "Security",
                    id: "security",
                    icon: <LockIcon/>,
                }
            ],
            advanced: [
                {
                    label: "Organisations",
                    id: "organisations",
                    icon: <GroupWorkIcon/>,
                },
                {
                    label: "Domains",
                    id: "domains",
                    icon: <DnsIcon/>,
                },
                {
                    label: "API",
                    id: "api",
                    icon: <VpnKey />,
                },
                {
                    label: "Theming",
                    id: "theming",
                    icon: <Palette />,
                },
            ]

        }

        const renderSettingsDrawer = () => {
            return (
                <Grid item xs={2} md={3}>
                    <List sx={{ pt: 0 }}>
                        <ListItemButton component={Link} to="/" sx={{ borderRadius: "20px 0 0 0"}}>
                            <ListItemIcon><ArrowBackIcon color="secondary"/></ListItemIcon>
                            <ListItemText primary={t("Back")} color={"secondary"} primaryTypographyProps={{ color: "secondary" }} />
                        </ListItemButton>

                        <ListSubheader sx={styles.subheader}>{t("Settings_global")}</ListSubheader>

                        {
                            settingsList.basic.map((settingTab: any, i: any) => (
                                <ListItemButton id={settingTab.id} onClick={handleActivePage} key={i}>
                                    <ListItemIcon>{settingTab.icon}</ListItemIcon>
                                    {!downMd && <ListItemText primary={t(settingTab.label)}/>}
                                </ListItemButton>
                            ))
                        }

                        <ListSubheader sx={styles.subheader}>{t("Settings_advanced")}</ListSubheader>

                        {
                            settingsList.advanced.map((settingTab: any, i: any) => (
                                <ListItemButton id={settingTab.id} onClick={handleActivePage} key={i}>
                                    <ListItemIcon>{settingTab.icon}</ListItemIcon>
                                    {!downMd && <ListItemText primary={t(settingTab.label)}/>}
                                </ListItemButton>
                            ))
                        }
                    </List>
                </Grid>
            )
        }

        const renderResponsiveSettings = () => {
            return (
                <Grid item xs={12} justifyContent={"center"}>
                    <Stack direction={"row"} spacing={1} justifyContent={"center"} sx={styles.responsiveStack}>
                        {
                            settingsList.basic.map((setting: any, i: number) => (
                                <IconButton
                                    id={setting.id}
                                    onClick={handleActivePage}
                                    sx={{ color: activePage === setting.id ? "primary.main" : "rgba(0,0,0,.54)" }}
                                    key={i}
                                >
                                    {setting.icon}
                                </IconButton>
                            ))
                        }
                        {
                            settingsList.advanced.map((setting: any, i: number) => (
                                <IconButton
                                    id={setting.id}
                                    onClick={handleActivePage}
                                    sx={{ color: activePage === setting.id ? "primary.main" : "rgba(0,0,0,.54)" }}
                                    key={i}
                                >
                                    {setting.icon}
                                </IconButton>
                            ))
                        }
                    </Stack>
                </Grid>
            )
        }

        return (
            <>{downMd ? renderResponsiveSettings() : renderSettingsDrawer()}</>
        )
    }

    return (
        <main>
            <Container maxWidth="lg" style={{padding: "32px 0px 32px 0px"}}>

                {
                    downMd &&
                    <Button
                        component={Link}
                        to={"/"}
                        variant={"outlined"}
                        color={"secondary"}
                        startIcon={<ArrowBackIcon color="secondary"/>}
                        sx={{ mb: 2 }}
                    >
                        {t("back")}
                    </Button>
                }

                <Paper variant="outlined" sx={styles.paper}>
                    <Grid container alignItems="flex-start" justifyContent="space-between">
                        {renderSettingsMenu()}
                        <Grid item xs={12} md={9}>
                            {renderActivePage()}
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </main>
    );
}
